/* eslint-disable no-param-reassign */
import { css } from "@emotion/react"
import React, { useState, useEffect } from "react"
import { theme } from "common/colorScheme"
import { BreakPoints, mediaBreakpoint } from "settings/breakpoints"
import useIntersectionObserver from "utils/useIntersectionObserver"
import { isAndroid, isIOS } from "components/Form/utils"
import Play from "../static/play.svg"
import PlayCircle from "../static/play-circle-shadow.svg"
import Pause from "../static/pause.svg"
import VolumeHigh from "../static/volume-high.svg"
import VolumeMute from "../static/volume-mute.svg"
import Fullscreen from "../static/fullscreen.svg"
import Share from "../static/share.svg"

interface IControlsOverlayProps {
	videoRef: React.MutableRefObject<HTMLVideoElement>
	playWhenInView?: boolean
	videoShareUrl?: string
}

const controlsOverlayContainer = css`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 2;
`

const mobilePlayOverlay = css`
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

	&.visible {
		opacity: 1;
	}
`
const mobilePlayIcon = css`
	height: 70px;
	width: 70px;
`

const controlBar = css`
	position: absolute;
	height: 75px;
	width: 100%;
	left: 0;
	bottom: 0;
	background: rgb(0, 0, 0);
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
	display: flex;
	flex-direction: column;
	padding: ${theme.spacing(1, 1)};
	border-radius: 0 0 8px 8px;
	opacity: 0;
	transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

	&.visible {
		opacity: 1;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		padding: ${theme.spacing(3, 4)};
		height: 130px;
	}
`

const buttonStyles = css`
	position: relative;
	background-color: transparent;
	border: none;
	cursor: pointer;
	opacity: 0.8;
	transition: all 0.1s ease-in-out;

	&:hover,
	&.active {
		transform: scale(1.1);
		opacity: 1;
	}

	img {
		height: 30px;
		width: 30px;
		filter: invert(1);

		${mediaBreakpoint(BreakPoints.MD)} {
			height: 35px;
			width: 35px;
		}
	}
`

const notificationWrapper = css`
	position: absolute;
	background-color: ${theme.color.white};
	border-radius: 5px;
	bottom: calc(100% + 10px);
	left: -100%;
	transform: translateX(-50%);
	white-space: nowrap;
	padding: 10px 20px;
	font-size: 1.3rem;
	opacity: 0;
	pointer-events: none;
	transition: all 0.2s ease-in-out;
	transform: translateX(-50%) translateY(5px);

	${mediaBreakpoint(BreakPoints.LG)} {
		left: 50%;
	}

	&.visible {
		opacity: 1;
		pointer-events: all;
		transform: translateX(-50%) translateY(0px);
	}
`

const centerXY = css`
	display: flex;
	justify-content: center;
	align-content: center;
`

const controlTop = css`
	visibility: hidden;

	${mediaBreakpoint(BreakPoints.MD)} {
		visibility: visible;
		flex: 1;
	}
`
const seekSlider = css`
	width: 100%;
	margin: 0 10px;
	cursor: pointer;
`

const controlBottom = css`
	display: flex;
	justify-content: space-between;
`

const controlLeft = css``
const controlRight = css``

const volumeContainer = css`
	position: relative;

	&:hover {
		.volumeSlider {
			opacity: 1;
			transform: rotate(270deg) translateX(0px);
		}
	}
`

const volumeSliderContainer = css`
	position: absolute;
	bottom: 100%;
	left: 0;
	height: 130px;
	width: 100%;
	display: flex;
	justify-content: center;
	pointer-events: none;
	visibility: hidden;

	&.pointerEventsActive {
		pointer-events: all;
	}

	${mediaBreakpoint(BreakPoints.MD)} {
		visibility: visible;
	}
`

const volumeInput = css`
	opacity: 0;
	position: relative;
	transform: rotate(270deg) translateX(-20px);
	width: 100px;
	height: 20px;
	bottom: -45%;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
`

const ControlsOverlay: React.FC<IControlsOverlayProps> = ({ playWhenInView, videoRef, videoShareUrl }) => {
	const [playing, setPlaying] = useState(false)
	const [volumeBtnActive, setVolumeBtnActive] = useState(false)
	const [volumeBarActive, setVolumeBarActive] = useState(false)
	const [volume, setVolume] = useState(0.6)
	const [volumeMuted, setVolumeMuted] = useState(false)
	const [currentTime, setCurrentTime] = useState(0)
	const [currentTimeLocal, setCurrentTimeLocal] = useState(0)
	const [seekBarActive, setSeekBarActive] = useState(false)
	const [manualPlayOverride, setManualPlayOverride] = useState(false)
	const [controlsVisible, setControlsVisible] = useState(false)
	const [clipboardNotificationVisible, setClipboardNotificationVisible] = useState(false)

	const volumeActive = volumeBtnActive || volumeBarActive
	const playButtonVisible = Play
	const deviceIsIOS = isIOS()
	const deviceIsMobile = deviceIsIOS || isAndroid()
	const fullScreenButtonVisible = Fullscreen
	const volumeButtonVisible = VolumeHigh && !deviceIsIOS
	const entry = useIntersectionObserver(videoRef, { threshold: 0.5 })
	const videoVisible = !!entry?.isIntersecting
	// eslint-disable-next-line no-restricted-globals
	const videoLength = videoRef.current?.duration && !isNaN(videoRef.current?.duration) ? videoRef.current?.duration : 0

	const handleVolumeBtnMouseEnter = () => setVolumeBtnActive(true)
	const handleVolumeBtnMouseLeave = () => setVolumeBtnActive(false)
	const handleVolumeBtnClick = () => {
		if (volumeMuted) {
			if (videoRef.current?.muted) videoRef.current.muted = false
			setVolumeMuted(false)
		} else {
			if (videoRef.current?.muted) videoRef.current.muted = true
			setVolumeMuted(true)
		}
	}

	const handleVolumeBarMouseEnter = () => setVolumeBarActive(true)
	const handleVolumeBarMouseLeave = () => setVolumeBarActive(false)
	const handleVolumeChange = (target: EventTarget & HTMLInputElement) => setVolume(Number(target.value))

	const handleOverlayMouseEnter = () => setControlsVisible(true)
	const handleOverlayMouseLeave = () => setControlsVisible(false)

	const pauseVideo = () => videoRef.current?.pause()
	const playVideo = () => videoRef.current?.play()

	const handlePlayPauseBtn = () => {
		setManualPlayOverride(true)
		return playing ? pauseVideo() : playVideo()
	}

	const handleSeekBarFocus = () => {
		setSeekBarActive(true)
		pauseVideo()
	}
	const handleSeekBarBlur = () => {
		setSeekBarActive(false)
		playVideo()
	}
	const handleSeekChange = (target: EventTarget & HTMLInputElement) => setCurrentTimeLocal(Number(target.value))
	const handleFullScreen = async () => {
		if (videoRef.current?.requestFullScreen) {
			await videoRef.current?.requestFullScreen()
		} else if (videoRef.current?.mozRequestFullScreen) {
			await videoRef.current?.mozRequestFullScreen()
		} else if (videoRef.current?.webkitEnterFullScreen) {
			await videoRef.current?.webkitEnterFullScreen()
		}
	}
	const handleShareBtn = () => {
		navigator.clipboard.writeText(videoShareUrl)
		setClipboardNotificationVisible(true)
		setTimeout(() => setClipboardNotificationVisible(false), 3000)
	}

	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.addEventListener("timeupdate", ({ target }) => setCurrentTime(target.currentTime))
			videoRef.current.addEventListener("play", () => setPlaying(true))
			videoRef.current.addEventListener("pause", () => setPlaying(false))
		}

		return () => {
			if (videoRef.current) {
				videoRef.current.removeEventListener("timeupdate", ({ target }) => setCurrentTime(target.currentTime))
				videoRef.current.removeEventListener("play", () => setPlaying(true))
				videoRef.current.removeEventListener("pause", () => setPlaying(false))
			}
		}
	}, [])

	useEffect(() => {
		if (videoRef.current?.muted) setVolumeMuted(videoRef.current?.muted)
	}, [videoRef.current?.muted])

	useEffect(() => {
		if (videoRef.current?.volume >= 0) {
			const newVolume = volumeMuted ? 0 : volume
			videoRef.current.volume = newVolume
		}
	}, [volume, volumeMuted, videoRef.current?.volume])

	useEffect(() => {
		if (videoRef.current) videoRef.current.currentTime = currentTimeLocal
	}, [currentTimeLocal])

	useEffect(() => {
		if (videoRef.current && playWhenInView && !deviceIsIOS) {
			if (videoVisible && !playing && !manualPlayOverride) playVideo()
			if (!videoVisible && playing && !manualPlayOverride) pauseVideo()
		}
	}, [videoVisible])

	return (
		<div css={controlsOverlayContainer} onMouseEnter={handleOverlayMouseEnter} onMouseLeave={handleOverlayMouseLeave}>
			{deviceIsMobile && (
				<div css={mobilePlayOverlay} className={!controlsVisible && !playing ? "visible" : ""}>
					<div css={mobilePlayIcon} onMouseDown={playVideo} role="button" tabIndex={0}>
						<img src={PlayCircle} alt="Play Button" />
					</div>
				</div>
			)}
			<div css={controlBar} className={controlsVisible || clipboardNotificationVisible ? "visible" : ""}>
				<div css={[controlTop, centerXY]}>
					<input
						type="range"
						css={seekSlider}
						min={0}
						max={videoLength}
						step={0.5}
						value={seekBarActive ? currentTimeLocal : currentTime}
						onChange={({ target }) => handleSeekChange(target)}
						onMouseDown={handleSeekBarFocus}
						onMouseUp={handleSeekBarBlur}
					/>
				</div>
				<div css={controlBottom}>
					<div css={[controlLeft, centerXY]}>
						{playButtonVisible && (
							<button css={buttonStyles} type="button" onMouseDown={handlePlayPauseBtn}>
								{!playing ? <img src={Play} alt="Play" /> : <img src={Pause} alt="Pause" />}
							</button>
						)}
					</div>
					<div css={[controlRight, centerXY]}>
						{volumeButtonVisible && (
							<div css={volumeContainer}>
								<div
									css={volumeSliderContainer}
									className={volumeActive ? "pointerEventsActive" : ""}
									onMouseEnter={handleVolumeBarMouseEnter}
									onMouseLeave={handleVolumeBarMouseLeave}
								>
									<input
										type="range"
										css={volumeInput}
										className="volumeSlider"
										value={volumeMuted ? 0 : volume}
										onChange={({ target }) => handleVolumeChange(target)}
										min={0}
										max={1}
										step={0.05}
									/>
								</div>
								<button
									css={buttonStyles}
									className={volumeActive ? "active" : ""}
									type="button"
									onMouseEnter={handleVolumeBtnMouseEnter}
									onMouseLeave={handleVolumeBtnMouseLeave}
									onMouseDown={handleVolumeBtnClick}
								>
									<img src={volumeMuted ? VolumeMute : VolumeHigh} alt="Volume" />
								</button>
							</div>
						)}
						{videoShareUrl && (
							<button css={buttonStyles} type="button" onMouseDown={handleShareBtn}>
								<img src={Share} alt="Share" />
								<div css={notificationWrapper} className={clipboardNotificationVisible && "visible"}>
									Share link has been copied to clipboard!
								</div>
							</button>
						)}
						{fullScreenButtonVisible && (
							<button css={buttonStyles} type="button" onMouseDown={handleFullScreen}>
								<img src={Fullscreen} alt="Fullscreen" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ControlsOverlay
